require('../css/login.scss');
// jQuery
const $ = require('jquery');
global.$ = global.jQuery = $; //  Pour que jquery soit dispo partout
//  Bootstrap
require('bootstrap');

//  Routeur symfony
const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);
global.Routing = Routing;

$("#reset-pwd-button").click(function(e) {
  e.preventDefault;
  console.log("reset pwd demandé");
  $("#reset-pwd-modal").modal('show');
});

$("#ask-new-pwd-button").click(function(e) {
  e.preventDefault;
  var formDatas = $("#reset-pwd-form").serialize();
  var url = Routing.generate('reset_pwd');
  $.post(url, formDatas)
    .done(function( data ) {
      console.log(data);
    })
    .fail(function( data ) {

    })
    ;
  $("#reset-pwd-modal").modal('hide');
});

//  Affiche une indication de chargement du js
console.log('login.js chargé');
